'use strict';

angular.module('enervexSalesappApp').controller('UsersCtrl', function($scope, $http, Auth, User, _, UserRole, Flash) {
	$scope.allUsers = [];
	$scope.users = [];
	$scope.offset = 0;
	$scope.limit = 100;
	$scope.status = "active"
	$scope.userRoles = UserRole.query();
	function fetch() {
		User.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(users){
			if (users.length > 0) {
				$scope.allUsers = $scope.allUsers.concat(users);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.selectFilter();
			}
		});
	}
	fetch();

	$scope.sortType = 'name.last';
	$scope.sortReverse = false;
	$scope.selectFilter = function() {
		var nameReg = ($scope.nameFilter && $scope.nameFilter != '') ? RegExp($scope.nameFilter, "i") : null;

		$scope.users = _.filter($scope.allUsers, function(user){
			if (nameReg && !nameReg.test(user.email) && !nameReg.test(user.fullName) ){
				return false;
			} else if ($scope.filtered && $scope.filtered != '' && $scope.filtered != user.role._id) {
				return false
			} else if ($scope.status && $scope.status != '' && $scope.status != user.status) {
				return false;
			} else {
				return true
			}
		})
	}
	$scope.export = function() {
		var params = [];
		// if ($scope.upload.application && $scope.upload.application._id){
		// 	params.push("application="+$scope.upload.application._id)
		// }
		// if ($scope.upload.applianceCompany && $scope.upload.applianceCompany._id){
		// 	params.push("applianceCompany="+$scope.upload.applianceCompany._id)
		// }
		// if ($scope.upload.applianceModel && $scope.upload.applianceModel.model){
		// 	params.push("model="+$scope.upload.applianceModel.model)
		// }
		// if ($scope.upload.isDuplicate && $scope.upload.isDuplicate != ""){
		// 	params.push("isDuplicate="+$scope.upload.isDuplicate)
		// }
		var url = "/api/users/export";
		if (params.length >0) {
			url = url + "?" + params.join("&")
		}
		window.location.href = url;
	}
	$scope.lock = function(user) {
		var payload = _.clone(user)
		payload.status = "locked"
		User.update({
			id: payload._id
		}, payload).$promise.then(function(result){
			
			$scope.success = true;
			$scope.allUsers = _.map($scope.allUsers, function(u){
				if (u._id == result._id) {
					return result
				} else {
					return u
				}
			})
			$scope.selectFilter()
			Flash.create('success', '<strong>Success!</strong> Successfully locked user.', 0, {}, true);
		}).catch(function(err){
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = (err.data && err.data.message) || "There was an error saving the user";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		});	}
});
